import i18n from "src/core/configs/i18n";

interface CarouselCard {
  CompanyName: string;
  CompanyDescription: string;
  imgSrc: string;
}
function CarouselCard({
  CompanyName,
  CompanyDescription,
  imgSrc,
}: CarouselCard) {
  return (
    <div
      className={`flex flex-col gap-y-3 lg:gap-y-0 lg:grid lg:gap-x-20 xl:gap-x-1 ${
        i18n.language === "ar" ? "lg:-translate-x-6" : "lg:translate-x-6"
      } lg:grid-cols-5 lg:grid-rows-3`}
    >
      {/* Image ! */}
      <div
        className={`flex self-center lg:w-36 xl:w-[90%] laptop:h-20 w-full lg:col-span-2 lg:row-span-3 justify-center rounded-xl bg-white lg:items-center `}
      >
        <img
          className={`h-36 laptop:h-24 w-[50%] object-contain lg:w-24`}
          src={imgSrc}
          alt={CompanyName}
        />
      </div>

      <div
        className={`self-start lg:col-span-3 lg:row-span-1 lg:translate-y-0`}
      >
        <p
          className={` ${
            i18n.language === "ar"
              ? "font-arMyriad text-2xl"
              : "font-helveticaMedium text-based"
          }`}
        >
          {CompanyName}
        </p>
      </div>

      <div className={"w-full lg:w-[80%] lg:col-span-3 lg:row-span-2"}>
        <p
          className={`line-clamp-4 font-thin text-naturalGray400 text-ellipsis largeLaptop:line-clamp-2 laptop:line-clamp-2 ${
            i18n.language === "ar"
              ? "font-arMyriad text-lg"
              : "font-helveticaMedium text-sm"
          }`}
        >
          {CompanyDescription}
        </p>
      </div>
    </div>
  );
}

export default CarouselCard;
