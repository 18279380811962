/* eslint-disable import/named */
import {
  Formik,
  useFormik,
  FormikTouched,
  FormikValues,
  FormikErrors,
} from "formik";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Popup from "src/components/common/pop-up";
import ProgramSteps from "src/components/common/program-steps";
import { RegistrationCardComponent } from "src/components/common/registration-card-component";
import { SharedButtonComponent } from "src/components/common/shared-button-component";
import GridView from "src/components/common/ui/grid-view";
import InputField from "src/components/common/ui/InputField";
import TextAreaField from "src/components/common/ui/textArea";
import i18n from "src/core/configs/i18n";
import { handleRequestError } from "src/core/helpers/error.helper";
import { BookTourList } from "src/core/types/tour.type";
import { bookTourApplication } from "src/services/book-tour-application.service";

import { bookTourList } from "./book-tour-form.data";
import { validationSchema } from "./book-tour-form.validation";

function BookTourFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [activeIndex, setActiveIndex] = useState(0);
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: bookTourList,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema(),
  });
  const handleSubmit = async () => {
    setIsLoading(!isLoading);
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();

        await bookTourApplication(
          {
            ...formik.values,
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
            mobile: formik.values.phoneNo,
            visit_reason: formik.values.reasonToVisit,
            hear_about_garage: formik.values.hearAboutGarage,
          },
          token
        );
        setSuccessfully(!successfully);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleBackClick = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + content.length) % content.length
    );
  };

  const fieldsToCheck: string[] = ["firstName", "lastName", "phoneNo", "email"];

  const content = [
    { label: "app.shared.personalInformation", color: "#737373" },
    { label: "app.shared.additionalInformation", color: "#737373" },
  ];
  const isLastSection = activeIndex === content.length - 1;

  const [isvalid, setIsvalid] = useState<boolean>(false);
  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<BookTourList>) => void;
      values: FormikValues;
      errors: FormikErrors<BookTourList>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any[]
  ) => {
    const newTouched: FormikTouched<BookTourList> = {};

    fieldsToCheck.forEach((field) => {
      if (!formik.values[field]) {
        newTouched[field] = true;
      }
    });

    formik.setTouched(newTouched);

    const hasErrors = fieldsToCheck.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );

    if (!hasErrors) {
      setIsvalid(!isvalid);
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + 1, content.length - 1)
      );
    }
  };
  const handlClick = () => {
    if (activeIndex !== 0) {
      handleBackClick();
    } else if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
    } else {
      handleSubmit();
    }
  };

  return (
    <RegistrationCardComponent
      title={"container.BookTourFormContainer.title"}
      description={"container.BookTourFormContainer.subTitle"}
    >
      <div className="h-[475px] largeLaptop:h-auto laptop:h-auto mobile:h-auto tablet:h-auto">
      <div className="flex gap-x-12 pt-10 laptop:pt-5 mobile:gap-x-6 tablet:gap-x-6 text-base text-neutral-500">
          {content.map((item, index) => (
            <ProgramSteps
              activeIndex={activeIndex}
              index={index}
              color={item.color}
              label={item.label}
              key={index}
              completed={activeIndex > index}
              onClick={handlClick}
            />
          ))}
        </div>
        <div className="w-full">
          <Formik
            initialValues={bookTourList}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            validateOnChange
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {activeIndex === 0 && (
                <GridView column={"2"}>
                  <InputField
                    label="app.shared.firstName"
                    name="firstName"
                    isValid={
                      formik.touched.firstName && !formik.errors.firstName
                    }
                    value={
                      formik.values.firstName != null
                        ? formik.values.firstName
                        : ""
                    }
                    errorMsg={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("firstName", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.lastName"
                    name="lastName"
                    isValid={formik.touched.lastName && !formik.errors.lastName}
                    value={
                      formik.values.lastName != null
                        ? formik.values.lastName
                        : ""
                    }
                    errorMsg={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("lastName", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.mobile.number"
                    name="phoneNo"
                    helperText={""}
                    errorMsg={
                      formik.touched.phoneNo && formik.errors.phoneNo
                        ? formik.errors.phoneNo
                        : ""
                    }
                    minLength={9}
                    isValid={formik.touched.phoneNo && !formik.errors.phoneNo}
                    value={
                      formik.values.phoneNo != null
                        ? formik.values.phoneNo
                        : NaN
                    }
                    onChange={(e) => {
                      formik.setFieldValue("phoneNo", e.target.value);
                      formik.handleChange(e);
                    }}
                  />

                  <InputField
                    label="app.shared.email"
                    name="email"
                    isValid={formik.touched.email && !formik.errors.email}
                    value={
                      formik.values.email != null ? formik.values.email : ""
                    }
                    errorMsg={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("email", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                </GridView>
              )}
              {activeIndex === 1 && (
                <GridView column={"1"}>
                  <div className="flex gap-6 mobile:flex-col tablet:flex-col">
                    <InputField
                      label="app.shared.position"
                      name="position"
                      isValid={
                        formik.touched.position && !formik.errors.position
                      }
                      value={
                        formik.values.position != null
                          ? formik.values.position
                          : ""
                      }
                      errorMsg={
                        formik.touched.position && formik.errors.position
                          ? formik.errors.position
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("position", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    <InputField
                      label="app.shared.company"
                      name="company"
                      isValid={formik.touched.company && !formik.errors.company}
                      value={
                        formik.values.company != null
                          ? formik.values.company
                          : ""
                      }
                      errorMsg={
                        formik.touched.company && formik.errors.company
                          ? formik.errors.company
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("company", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                  <InputField
                    label="app.shared.wayOfLinking"
                    name="hearAboutGarage"
                    isValid={
                      formik.touched.hearAboutGarage &&
                      !formik.errors.hearAboutGarage
                    }
                    value={
                      formik.values.hearAboutGarage != null
                        ? formik.values.hearAboutGarage
                        : ""
                    }
                    errorMsg={
                      formik.touched.hearAboutGarage &&
                      formik.errors.hearAboutGarage
                        ? formik.errors.hearAboutGarage
                        : ""
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <TextAreaField
                    label="app.shared.reasonToVisit"
                    name="reasonToVisit"
                    isValid={
                      formik.touched.reasonToVisit &&
                      !formik.errors.reasonToVisit
                    }
                    value={
                      formik.values.reasonToVisit != null
                        ? formik.values.reasonToVisit
                        : ""
                    }
                    errorMsg={
                      formik.touched.reasonToVisit &&
                      formik.errors.reasonToVisit
                        ? formik.errors.reasonToVisit
                        : ""
                    }
                    limit={350}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                </GridView>
              )}
            </form>
          </Formik>
        </div>
      

<SharedButtonComponent isLoading={isLoading} isLastSection={isLastSection} activeIndex={activeIndex} handleBackClick={handleBackClick}  onClick={() => {
              if (activeIndex === 0) {
                handleNext(fieldsToCheck, formik, setActiveIndex, content);
              }
              if (isLastSection) {
                formik.handleSubmit();
              }
            }} />
        {successfully && (
          <div
            className={`z-50 absolute h-full inset-0 ${
              i18n.language === "en"
                ? "left-80 mobile:left-0"
                : "right-80 mobile:right-0"
            } mobile:top-0 mobile:left-0 tablet:top-80 shadow-2xl`}
          >
            <div className="flex justify-center mx-auto items-center h-full">
              <Popup
                body="popup-successfully"
                onClose={() => {
                  setSuccessfully(false);
                  formik.resetForm();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </RegistrationCardComponent>
  );
}

export default BookTourFormContainer;
