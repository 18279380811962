import {
  onlyLetters_Ar_En,
  onlyNumbers_En,
} from "src/components/common/ui/regex";
import * as Yup from "yup";
export const validationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.first.name.no.numbers")
      .required("form.validation.first.name.required")
      .min(2, "form.validation.first.name.min.2")
      .max(20, "form.validation.first.name.max.20"),
    lastName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.last.name.no.numbers")
      .required("form.validation.last.name.required"),
    email: Yup.string()
      .trim()
      .required("form.validation.email.required")
      .max(50, "form.validation.email.max.50")
      .email("form.validation.email.valid.format"),
    mobile: Yup.string()
      .trim()
      .required("form.validation.mobile.required")
      .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
      .min(9, "form.validation.mobile.min.9")
      .max(15, "form.validation.mobile.max.15"),
    company: Yup.string()
      .trim()
      .required("form.validation.company.name.required")
      .max(50, "form.validation.company.name.max.50"),

    position: Yup.string().required("form.validation.position.required"),

    event_name: Yup.string().required("form.validation.event.name.required"),

    event_type: Yup.string().required("form.validation.event.type.required"),
    start_at: Yup.string().required("form.validation.event.from.required"),
    end_at: Yup.string().required("form.validation.event.to.required"),
    guest_count: Yup.string()
      .required("form.validation.number.of.people.required")
      .matches(onlyNumbers_En, "form.validation.number.of.people.letters.only"),
  });
