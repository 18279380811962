import React from "react";
import NewsSectionComponent from "src/components/common/Sidebar-component/news-section-component";
import SidebarComponent from "src/components/common/Sidebar-component/sidebar-component";

export default function MobileThegaragePage() {
  return (
    <div>
      <SidebarComponent />
      <NewsSectionComponent />
    </div>
  );
}
