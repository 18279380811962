import { Formik, useFormik } from "formik";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import Popup from "src/components/common/pop-up";
import { ReportProblemButtonComponent } from "src/components/common/report-problem-button-component";
import InputField from "src/components/common/ui/InputField";
import TextAreaField from "src/components/common/ui/textArea";
import i18n from "src/core/configs/i18n";
import { handleRequestError } from "src/core/helpers/error.helper";
import { postContactUsService } from "src/services/contact-us.service";

import { initialValues } from "./question-form-container.data";
import { validationSchema } from "./question-form-container.validation";

function QuestionFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [successfully, setSuccessfully] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      postContactUs();
    },
  });
  const postContactUs = async () => {
    setIsLoading(!isLoading);
    if (executeRecaptcha) {
      const token = await executeRecaptcha();
      try {
        await postContactUsService(
          {
            ...
            formik.values,
            description: formik.values.question
          }
          , token);
        setSuccessfully(!successfully);
      } catch (error) {
        handleRequestError(error);
      }
    }
  };

  return (
    <div className="px-2 mobile:px-0 mobile:overflow-y-auto mobile:h-[600px]">
      <h1
        className={`mb-10 font-bold text-center text-white ${
          i18n.language === "en"
            ? "font-helveticaMedium text-2xl"
            : " font-arMyriad text-[28px]"
        }`}
      >
        {t("app.shared.ask")}
      </h1>
      <Formik
        initialValues={initialValues}
        onSubmit={() => formik.submitForm()}
        validationSchema={formik.initialValues}
        enableReinitialize
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="grid gap-4">
            <InputField
              label="app.shared.firstName"
              name="first_name"
              type="text"
              isValid={formik.touched.first_name && !formik.errors.first_name}
              value={
                formik.values.first_name != null ? formik.values.first_name : ""
              }
              errorMsg={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("first_name", e.target.value);
                formik.handleChange(e);
              }}
            />
            <InputField
              label="app.shared.email"
              name="email"
              type="email"
              isValid={formik.touched.email && !formik.errors.email}
              value={formik.values.email != null ? formik.values.email : ""}
              errorMsg={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                formik.handleChange(e);
              }}
            />

            <InputField
              label="app.shared.title"
              name="title"
              type="text"
              isValid={formik.touched.title && !formik.errors.title}
              value={formik.values.title != null ? formik.values.title : ""}
              errorMsg={
                formik.touched.title && formik.errors.title
                  ? formik.errors.title
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("title", e.target.value);
                formik.handleChange(e);
              }}
            />
            <TextAreaField
              label="app.shared.yourQuestion"
              name="question"
              isValid={formik.touched.question && !formik.errors.question}
              value={
                formik.values.question != null ? formik.values.question : ""
              }
              errorMsg={
                formik.touched.question && formik.errors.question
                  ? formik.errors.question
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("question", e.target.value);
              }}
              limit={350}
            />
          </div>
          <ReportProblemButtonComponent isLoading={isLoading} />

          {successfully && (
            <div
              className={`z-50 absolute h-full inset-0 ${
                i18n.language === "en"
                  ? "left-80 mobile:left-0"
                  : "right-80 mobile:right-0"
              } mobile:top-0 mobile:left-0 tablet:top-80 `}
            >
              <div className="flex justify-center mx-auto items-center h-full">
                <Popup
                  body="popup-successfully"
                  onClose={() => {
                    setSuccessfully(false);
                    formik.resetForm();
                  }}
                />
              </div>
            </div>
          )}
        </form>
      </Formik>
    </div>
  );
}

export default QuestionFormContainer;
