export enum NewsTypesEnum {
  NEWS_LETTER = "news_letter",
  NEWS_VIDEO = "news_video",
}

export enum IdentityEnum {
  PASSPORT_NUMBER = "PASSPORT_NUMBER",
  RESIDENCY_NUMBER = "RESIDENCY_NUMBER",
  ID_NUMBER = "ID_NUMBER",
}
