/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "src/components/common/Loading/loading";
import { getErrorMessage2 } from "src/core/helpers/error.helper";
import useModeStore from "src/core/helpers/useModeStore";
import { ErrorType } from "src/core/types/error.type";
import { ScanPermitType } from "src/core/types/user.type";
import { getScanPermitService } from "src/services/permit.service";

export default function ScanPermitContainer() {
  const { t, i18n } = useTranslation();

  const { permit_id = "" } = useParams<{ permit_id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [data, setScanData] = useState<ScanPermitType>();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";


  const getPermitIdById = useCallback(async () => {
    try {
      const data = await getScanPermitService(permit_id.replace(":", ""));
      setScanData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMsg(getErrorMessage2((error as ErrorType)?.response?.data?.detail, t));
    }
  }, [permit_id, t]);

  useEffect(() => {
    if (permit_id) {
      getPermitIdById();
    }
  }, [getPermitIdById, permit_id]);

  const TextBlock = ({
    textKey,
    dataValue,
    isHeader = false,
  }: {
    textKey?: string;
    dataValue?: React.ReactNode;
    isHeader?: boolean;
  }) => {
    const commonClasses = "text-start w-full";
    const languageClasses =
      i18n.language === "en" ? "font-helveticaBold" : "font-arMyriad";

    return (
      <div
        className={`${commonClasses} ${languageClasses} ${isHeader
          ? "text-white text-center justify-center " +
          (i18n.language === "en" ? "text-sm" : "text-2xl")
          : "text-naturalGray400 pb-6 " +
          (i18n.language === "en" ? "text-sm" : "text-lg")
          }`}
      >
        {dataValue || t(textKey!).replace('*','')}
      </div>
    );
  };

  return (
    <div >
      
      {loading ? (
        <Loading />
      ) : errorMsg ? (
        <div className="flex flex-col items-center justify-center h-full text-center mt-28 gap-y-6">
          <div>
            <img
              className="h-44"
              alt="notfound"
              src={`/assets/images/not-found-${isDarkMode ? "dark" : "light"
                }.png`}
            />
          </div>
          <div
            className={`text-white justify-center text-center w-full pb-10 text-sm ${i18n.language == "en" ? "font-helveticaBold" : "font-arMyriad"
              }`}
          >
            {errorMsg}
          </div>
        </div>
      ) : (
        <div className="px-1">
          <TextBlock
            textKey="app.shared.driverName"
            isHeader={true}
          />
          <TextBlock dataValue={data?.name} />
          <TextBlock
            textKey="app.shared.nationalty"
            isHeader={true}
          />
          <TextBlock dataValue={data?.nationality} />
          <TextBlock
            textKey="container.ScanPermitContainer.vehicle"
            isHeader={true}
          />
          <TextBlock dataValue={data?.vehicle} />
          <TextBlock
            textKey="form.lapel.Equipment"
            isHeader={true}
          />
          <TextBlock dataValue={data?.materials[0].equipment} />
          <TextBlock
            textKey={"form.lapel.Quantity"}
            isHeader={true}
          />
          <TextBlock dataValue={data?.materials[0].quantity} />
        </div>
      )}
    </div>
  );
}
