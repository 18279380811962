/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  onlyLetters_Ar_En,
  onlyNumbers_En,
} from "src/components/common/ui/regex";
import * as Yup from "yup";

const supportedFormats = ["pdf", "docx", "doc"];

export const validationSchema = (startupProfileCopy: HTMLInputElement) =>
  Yup.object({
    firstName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.first.name.no.numbers")
      .required("form.validation.first.name.required")
      .min(2, "form.validation.first.name.min.2")
      .max(20, "form.validation.first.name.max.20"),
    lastName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.last.name.no.numbers")
      .required("form.validation.last.name.required"),
    email: Yup.string()
      .trim()
      .required("form.validation.email.required")
      .max(50, "form.validation.email.max.50")
      .email("form.validation.email.valid.format"),
    phoneNo: Yup.string()
      .trim()
      .required("form.validation.mobile.required")
      .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
      .min(9, "form.validation.mobile.min.9")
      .max(15, "form.validation.mobile.max.15"),
    gender: Yup.string().trim().required("form.validation.gender.required"),
    nationality: Yup.string()
      .trim()
      .required("form.validation.nationlity.required")
      .max(255, "form.validation.nationlity.max.255"),

    startupName: Yup.string()
      .trim()
      .required("form.validation.company.name.required")
      .max(50, "form.validation.company.name.max.50"),
    yearOfEstablishment: Yup.number().required(
      "form.validation.year.of.establishment.required"
    ),
    position: Yup.string()
      .required("form.validation.position.required")
      .max(255, "form.validation.position.max.255"),

    startupMajor: Yup.string().required("form.validation.industry.required"),

    website: Yup.string()
      .trim()
      .url("form.validate.website.format")
      .required("form.validate.website.required"),
    twitter: Yup.string(),
    instagram: Yup.string(),
    aboutStartup: Yup.string()
      .required("form.validation.about.company.required")
      .max(500, "form.validation.about.company.max.500"),
    startupProfile: Yup.mixed()
      .required("form.validation.company.file.required")
      .test(
        "fileSize",
        "form.validation.company.file.size",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (value) => value && startupProfileCopy.files![0].size < 2000000
      )
      .test("fileFormat", "form.validation.company.file.type", (value) => {
        if (value) {
          return supportedFormats.includes(
            value && startupProfileCopy.files?.[0].name.split(".").pop()
          );
        }
        return true;
      }),
    startup_last_fundraising_round: Yup.string().required(
      "form.validate.product.stage.required"
    ),
    hearAboutGarage: Yup.string()
      .required("form.validation.hearAboutTheGarage.required")
      .max(255, "form.validation.hearAboutTheGarage.max.255"),
    reasonToJoin: Yup.string()
      .max(350, "form.validate.AttendTheGarage.max.350")
      .required("form.validation.reason.to.attend.required"),
  });
