import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CardComponent from "./card-component";

interface ICommunityCardComponentProps {
  title: string;
  path: string;
}

export default function CommunityCardComponent({
  title,
  path,
}: ICommunityCardComponentProps) {
  const { t } = useTranslation();

  return (
    <Link to={path}>
      <CardComponent isHover={true}>
        <p
          className={"py-1.5 largeLaptop:py-0.5 laptop:py-0"}
        >
          {t(title)}
        </p>
      </CardComponent>
    </Link>
  );
}
