import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import SharedSectionComponent from "src/components/shard-Section";
import useModeStore from "src/core/helpers/useModeStore";
import { InputList } from "src/core/statics/inputList";

import NewsSectionComponent from "./news-section-component";


function SidebarComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";

  const hasCountUpRun = sessionStorage.getItem("hasCountUpRun") === "true";

  window.addEventListener("beforeunload", () => {
    sessionStorage.removeItem("hasCountUpRun");
  });

  return (
    <div className="mobile:py-0 tablet:pb-8 flex  w-[350px] mobile:overflow-hidden tablet:overflow-hidden mobile:pb-8 h-full largeLaptop:w-[300px] laptop:w-[290px] mobile:w-full tablet:w-full">
      <div
        className={`px-4 mobile:px-0 tablet:px-0 py-4 flex flex-col  tablet:w-full mobile:border-none border mobile:rounded-none tablet:border-none tablet:rounded-none rounded-2xl pb-3 
          ${isDarkMode ? "border-gray-600/50" : "border-naturalGray500"}`}
      >
        <SharedSectionComponent
          component={
            <div>
              <p
                className={` text-naturalGray400 line-clamp-2 ${
                  i18n.language === "ar"
                    ? "font-arMyriad text-lg "
                    : "font-helveticaMedium text-sm "
                }`}
              >
                {t("component.user.SidebarComponent.theGarageDescreption")}
              </p>

              <Link to={appRoutesObj.getAboutThegaragePagePath()}>
                <p
                  className={`text-xs text-yellow underline mb-8 mt-6 laptop:mt-0 laptop:mb-2 largeLaptop:mb-4 largeLaptop:mt-3 laptop:my-2 ${
                    isDarkMode
                      ? " hover:text-yellowDarkPrimmery300"
                      : " hover:text-yellowLightPrimmery300"
                  } ${
                    i18n.language === "ar"
                      ? " text-left font-arMyriad "
                      : "text-right font-helvetica font-light text-[11px]"
                  }`}
                >
                  {t("component.user.SidebarComponent.theGarageExplore")}
                </p>
              </Link>
              <div className="grid grid-cols-2 gap-4 laptop:gap-4 font-HelveticaCEMedium">
                {InputList.map((val, index) => (
                  <div
                    key={index}
                    className={`rounded-2xl h-16 laptop:h-14 largeLaptop:h-14 border bg-naturalGray500 bg-opacity-10 mobile:py-3 mobile:h-[110%] ${
                      isDarkMode
                        ? "border-gray-600/50"
                        : "border-naturalGray500"
                    } `}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-center">
                        <p className="mt-1 text-lg text-center laptop:text-sm text-secondaryGreen font-bold">
                          <CountUp
                            className="text-lg text-center text-secondaryGreen font-bold"
                            end={
                              typeof val.Number === "string"
                                ? parseFloat(val.Number)
                                : val.Number
                            }
                            duration={hasCountUpRun ? 0 : 2}
                            useGrouping={false}
                            onEnd={() => {
                              sessionStorage.setItem("hasCountUpRun", "true");
                            }}
                          />
                          {i18n.language === "en"
                            ? " " + val.subtext + " +"
                            : "" + val.subtext + " +"}
                        </p>
                      </div>
                      <p
                        className={`text-center text-whiteColor text-xs laptop:text-[9px] laptop:text-center mt-1 ${
                          i18n.language === "ar"
                            ? "font-arMyriad"
                            : "font-helveticaMedium"
                        }`}
                      >
                        {t(`${val.label}`)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          title="app.shared.theGarage"
        />
        <div className="mobile:hidden tablet:hidden h-full pt-8 pb-4 largeLaptop:pb-0 largeLaptop:pt-6 laptop:pt-2 laptop:pb-0">
          <NewsSectionComponent />
      
        </div>
      </div>
    </div>
  );
}

export default SidebarComponent;
