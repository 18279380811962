import { makeRequest, baseURL } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const postAcceleratedFormService = async (
    data: object,
    reCaptchaToken: string
  ) => {
    return await makeRequest({
      url: `${baseURL}/product/application`,
      method: HttpMethods.POST,
      data,
      headers: { "recaptcha-token": reCaptchaToken },
    });
  };