import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import SharedSectionComponent from "src/components/shard-Section";
import CardComponent from "src/components/user/card-component";
import CommunityCardComponent from "src/components/user/comunnaty-card-component";
import { linksData } from "src/core/statics/community.static";

import Carousel from "./Carousel";
import { getListPlusService } from "./startup/list-plus";

function CommunityContainer() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {i18n } = useTranslation();
  const [images, setImages] = useState<any[]>([]);
  const [page, setPage] = useState<string>("container.community.1.cohort");
  const getDisrupt = async (page: string) => {
    try {
      const disrupt = await getListPlusService(page);
      unstable_batchedUpdates(() => {
        setImages(disrupt);
        setPage(page);
      });
    } catch (error) {
      error;
    }
  };

  useEffect(() => {
    getDisrupt(page);
  }, [page]);

  return (
    <div className="w-full">
      <SharedSectionComponent
        component={
          <div className="grid lg:grid-cols-7 w-full mobile:grid-cols-1 tablet:grid-cols-1 ">
            <CardComponent className="lg:col-span-5">
              <Carousel Cards={images} autoPlay={false} />
            </CardComponent>

            <div className={`px-2 text-white ${
            i18n.language === "ar"
              ? "font-arMyriad text-2xl"
              : "font-helveticaMedium largeLaptop:text-[13px] laptop:text-xs"
          } col-span-2 grid gap-2 tablet:pt-4 mobile:pt-4 laptop:gap-1 text-center tablet:grid tablet:grid-cols-2 largeLaptop:px-2 w-full`}>
              <CommunityCardComponent
                title={linksData[0].title}
                path={linksData[0].path}
              />
              <CommunityCardComponent
                title={linksData[1].title}
                path={linksData[1].path}
              />
              <div className="col-span-2">
                <CommunityCardComponent
                  title={linksData[2].title}
                  path={linksData[2].path}
                />
              </div>
            </div>
          </div>
        }
        title={"container.user.community.communityTitle"}
      />
    </div>
  );
}
export default CommunityContainer;
