import { Formik, useFormik } from "formik";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";
import { RiImageAddLine } from "react-icons/ri";
import Popup from "src/components/common/pop-up";
import InputField from "src/components/common/ui/InputField";
import SelectComponent from "src/components/common/ui/select-component";
import TextAreaField from "src/components/common/ui/textArea";
import { handleRequestError } from "src/core/helpers/error.helper";
import * as list from "src/core/statics/form-data/form-data";
import { postIssuesServices } from "src/services/issue.service";
import { uploadImage, FileTypeEnum } from "src/services/upload.service";

import { validationSchema } from "./issue-form-container.validation";
import { issueInitialValues } from "./issue-form.data";

export interface Type {
  type: string;
}
function IssueContainer(props: Type) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation();
  const imgUploaded = document.getElementById("image_url") as HTMLInputElement;
  const [successfully, setSuccessfully] = useState<boolean>(false);

  const handleSubmit = async () => {
    const fileUploaded = imgUploaded.files?.[0];
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        if (fileUploaded) {
          const logo_extension = fileUploaded.type.replace("image/", "");
          const image = await uploadImage(
            FileTypeEnum.issue,
            logo_extension,
            fileUploaded
          );
          await postIssuesServices(
            props.type,
            { ...formik.values, image_url: [image] },
            token
          );
          setSuccessfully(!successfully);
          imgUploaded.value = "";
        }
      } else {
        handleRequestError(
          "Couldn't verify Google Recaptcha, refresh the page please"
        );
        return;
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const formik = useFormik({
    initialValues: issueInitialValues,
    validationSchema: validationSchema(imgUploaded),
    onSubmit: async () => {
      await handleSubmit();
    },
  });

  return (
    <div className="px-3 mobile:px-0 h-[540px] laptop:h-auto overflow-y-auto mobile:h-full mobile:w-full mobile:overflow-hidden">
      <h1
        className={`mb-10 ${
          i18n.language === "en"
            ? "font-helveticaMedium text-2xl"
            : "font-arMyriad text-xl"
        } text-center text-white`}
      >
        {t("containers.IssuesContainers.problem")}
      </h1>
      <Formik
        initialValues={issueInitialValues}
        onSubmit={() => formik.submitForm()}
        validationSchema={formik.initialValues}
        validateOnChange
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="grid gap-4">
            <InputField
              label="app.shared.email"
              name="email"
              type="email"
              isValid={formik.touched.email && !formik.errors.email}
              value={formik.values.email != null ? formik.values.email : ""}
              errorMsg={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                formik.handleChange(e);
              }}
            />
            <div className="flex gap-6 mobile:flex-col mobile:gap-2.5">
              <SelectComponent
                selectLabel="container.IssueContainer.title.browser"
                name="browser"
                options={
                  i18n.language === "en" ? list.browserType : list.browserTypeAr
                }
                value={formik.values.browser}
                errorMsg={
                  formik.touched.browser && formik.errors.browser
                    ? formik.errors.browser
                    : ""
                }
                onChange={(e) => {
                  formik.setFieldValue("browser", e.target.value);
                  formik.handleChange(e);
                }}
              />
              <SelectComponent
                selectLabel="container.IssueContainer.title.systemType"
                name="operating_system"
                options={
                  i18n.language === "en"
                    ? list.operationSystemType
                    : list.operationSystemTypeAr
                }
                value={formik.values.operating_system}
                errorMsg={
                  formik.touched.operating_system &&
                  formik.errors.operating_system
                    ? formik.errors.operating_system
                    : ""
                }
                onChange={(e) => {
                  formik.setFieldValue("operating_system", e.target.value);
                  formik.handleChange(e);
                }}
              />
            </div>
            <TextAreaField
              label="container.IssueContainer.title.problemDescription"
              name="details"
              isValid={formik.touched.details && !formik.errors.details}
              value={
                formik.values.details !== null ? formik.values.details : ""
              }
              errorMsg={
                formik.touched.details && formik.errors.details
                  ? formik.errors.details
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("details", e.target.value);
                formik.handleChange(e);
              }}
              limit={350}
            />
            <div>
              <div
                className={`backdrop-blur-lg p-4 rounded-lg bg-neutral-500/10 ${
                  formik.touched.image_url && formik.errors.image_url
                    ? "border border-danger"
                    : ""
                }`}
              >
                <div
                  className={`grid px-4 text-gray-400 ${
                    i18n.language === "ar"
                      ? "font-arMyriad"
                      : "font-helveticaMedium"
                  }`}
                >
                  <label htmlFor="image_url">
                    {t("container.IssueContainer.title.uploadPhoto")}
                    <div className=" pt-5 pb-2">
                      <div className="w-full h-full flex mx-auto justify-center flex-col items-center">
                        <label className="flex flex-col items-center">
                          <RiImageAddLine className="text-teal-500" size={18} />
                          <p
                            className={`text-teal-500 ${
                              i18n.language === "ar"
                                ? "font-arMyriad"
                                : "font-helveticaMedium"
                            }`}
                          >
                            {t("app.shared.clickUpload")}{" "}
                          </p>
                          <p
                            className={`text-xs ${
                              i18n.language === "ar"
                                ? "font-arMyriad"
                                : "font-helveticaMedium"
                            }`}
                          >
                            {t("app.shared.sizeFile")}
                          </p>
                          <input
                            type="file"
                            className="hidden"
                            name="image_url"
                            id="image_url"
                            onChange={(e) => {
                              formik.setFieldValue("image_url", e.target.value);
                              formik.handleChange(e);
                            }}
                            accept="image/jpg,image/jpeg,image/png"
                          />
                        </label>
                        {imgUploaded?.value && (
                          <div className="font-sm w-full p-3 rounded-lg text-gray-400 bg-neutral-500/10 mt-3">
                            <ul>
                              <li>
                                <div className="flex justify-between">
                                  {imgUploaded &&
                                    imgUploaded.files &&
                                    imgUploaded.files[0] &&
                                    imgUploaded.files[0].name}
                                  <button
                                    onClick={() => (imgUploaded.value = "")}
                                  >
                                    <BsX
                                      size={16}
                                      className="text-slate-400"
                                      onClick={() =>
                                        formik.setFieldValue("image_url", null)
                                      }
                                    />
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div>
                {formik.touched.image_url && formik.errors.image_url && (
                  <p
                    className={`text-xs text-danger ${
                      i18n.language === "en"
                        ? "font-helveticaMedium"
                        : "font-arMyriad"
                    }`}
                  >
                    {`${t(formik.errors.image_url)}`}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="grid justify-center items-center mt-5 mobile:static">
            <button
              type="submit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className={`px-10 h-10 leading-4 bg-yellow 
              rounded-lg ${
                i18n.language === "en"
                  ? "font-helveticaMedium text-sm"
                  : "font-arMyriad text-base font-bold"
              }`}
            >
              {t("app.shared.button.send")}
            </button>{" "}
          </div>
        </form>
      </Formik>
      {successfully && (
        <div
          className={`z-50 absolute h-full inset-0 ${
            i18n.language === "en"
              ? "left-80 mobile:left-0"
              : "right-80 mobile:right-0"
          } mobile:top-0 mobile:left-0 tablet:top-80 `}
        >
          <div className="flex justify-center mx-auto items-center h-full">
            <Popup
              body="popup-successfully"
              onClose={() => {
                setSuccessfully(false);
                formik.resetForm();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default IssueContainer;
