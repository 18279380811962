import { useTranslation } from "react-i18next";

import TitleComponent from "./common/title-component";

interface shardSection {
  component: JSX.Element;
  title: string;
}

export default function SharedSectionComponent(prop: shardSection) {
  const { i18n } = useTranslation();

  return (
    <div className="mobile:px-0 tablet:px-0">
      <TitleComponent
        title={prop.title}
        color="text-white"
        size={`${
          i18n.language === "en"
            ? "text-base laptop:text-sm mobile:text-[13px]"
            : "text-2xl laptop:text-xl "
        }`}
      />
      {prop.component}
    </div>
  );
}
