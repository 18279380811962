import i18n from 'src/core/configs/i18n';

function VideoSkeletonCard(): JSX.Element {
  return (
    <div
    className="h-56 rounded-2xl border border-gray-600/50 bg-neutral-500/10 p-3 backdrop-blur-lg mobile:bg-inherit mobile:backdrop-blur-[20px] largeLaptop:h-[180px] laptop:h-[155px]"
  >
    <div className="flex flex-col justify-center">
      <div className="h-[170px] w-full rounded-xl object-cover largeLaptop:h-[130px] laptop:h-[100px] bg-[#202020] animate-pulse"></div>
      <div className="pt-3">
        <div className={`h-4 bg-gray-600 rounded animate-pulse w-3/4 mb-2 ${i18n.language === 'en' ? 'font-helveticaMedium' : 'font-arMyriad'}`}></div>
      </div>
    </div>
  </div>
  );
}

export default VideoSkeletonCard;
