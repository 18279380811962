import {
    onlyLetters_Ar_En,
    onlyNumbers_En,
  } from "src/components/common/ui/regex";
  import * as Yup from "yup";
  export const acceleratedValidationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .matches(onlyLetters_Ar_En, "form.validation.name.no.numbers")
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.first.name.min.2")
        .max(20, "form.validation.first.name.max.20"),
        last_name: Yup.string()
        .matches(onlyLetters_Ar_En, "form.validation.name.no.numbers")
        .required("container.AcceleratedFormContainer.empty")
        .max(20, "form.validation.last.name.max.20"),
        
      mobile: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
        .min(9, "form.validation.mobile.min.9")
        .max(15, "form.validation.mobile.max.15"),
  
      email: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .max(50, "form.validation.email.max.50")
        .email("form.validation.email.valid.format"),
  
        product_name: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      product_main_idea: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      product_differentiation: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      idea_inspiration: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      target_audience: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      key_features: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      unique_design_concept: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),      
      additional_features: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),

      development_stage: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),      
      core_technology: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),      
      enhancement_plan: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      technical_challenges: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      testing_results: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      development_methodologies: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      user_experience_update_plan: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      new_technologies_adoption: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      market_size: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      market_entry_strategy: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      pricing_strategy: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      market_research: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      marketing_channels: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      competition_strategy: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      revenue_forecast: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      post_launch_market_strategy: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      distribution_plan: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      required_resources: Yup.string()
    
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      suppliers_partners: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      product_quality_plan: Yup.string()
      .required("container.AcceleratedFormContainer.empty")
      .min(2, "form.validation.min.2")
      .max(300, "form.validation.max.300"),
      
      logistical_challenges: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      essential_logistics: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      geographical_expansion: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      contingency_plans: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      personal_experiences: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      alignment_with_values: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      life_impact: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      personal_challenges: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      experience_shaping_vision: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      influences_on_creation: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      lessons_learned: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      contribution_to_life_goals: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      future_vision: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      market_trends_effect: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
      
      personal_professional_goals: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
        future_collaborations: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
        product_evolution_over_time: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
        staying_ahead_of_trends: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
                product_legacy: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
        motivation_to_improve: Yup.string()
        .required("container.AcceleratedFormContainer.empty")
        .min(2, "form.validation.min.2")
        .max(300, "form.validation.max.300"),
    });
  