import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import PopupTapsComponent from "src/components/common/pop-up-taps-components";
import TitleComponent from "src/components/common/title-component";
import { Button } from "src/components/common/ui/Button";
import Tabs from "src/components/common/ui/Taps";
import CardIncbatorComponent from "src/components/user/card-incubator";
// import { IncubatorIndividualContainer } from "src/components/user/incubator-timeline";
import SelectionCriteriaComponent from "src/components/user/selection-criteria-component";
import CardComponent from "src/components/user/V2/card-component";
import { ColorsEnum, VariantsEnum, SizesEnum } from "src/core/enums/ui.enums";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import {
  internalPrograms,
  selectionCriteriaIgnation,
} from "src/core/statics/internal-programs";

function InternalProgramsContainer() {
  const { t, i18n } = useTranslation();
  const [selectedProgram, setSelectedProgram] = useState<string>(
    internalPrograms[0].programActiveImg
  );
  const selectedProgramData = internalPrograms.find(
    (h) => h.programActiveImg === selectedProgram
  );
  const [index, setIndex] = useState<number>(0);
  const [subPage, setSubPage] = useState<number>(0);
  const title = [
    "containers.InternalProgramsContainer.aboutProgram",
    "containers.InternalProgramsContainer.programDetails",
  ];
  const firstTap = subPage === 0;
  const secondTap = subPage === 1;

  return (
    <>
      <Helmet
        title={`${t("app.shared.theGarage")} | ${t(
          "containers.user.landing.theGarageInternal"
        )}`}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")} | ${t(
              "containers.user.landing.theGarageInternal"
            )}`,
            description: `${t("app.shared.internalPrograms.description")}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t("app.shared.internalPrograms.description")}`,
          },
        ]}
      />
      <PopupTapsComponent
        link={appRoutesObj.getBasePath()}
        index={index}
        icon={<IoMdClose size={32} className="text-white" />}
        taps={
          <div className="flex gap-4 mobile:gap-0">
            {internalPrograms.map((items, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedProgram(items.programActiveImg);
                  setIndex(index);
                }}
                className={`flex justify-center rounded-t-2xl items-center text-center py-2 px-4 mobile:w-full tablet:w-28 w-[162px] h-10 cursor-pointer ${
                  selectedProgram === items.programActiveImg
                    ? "text-primary rounded-t-2xl backdrop-filter backdrop-blur-lg  mobile:backdrop-filter-none mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.1)] mobile:to-[rgba(120,113,108,0.1)]"
                    : ""
                }`}
              >
                <img
                  alt="logo"
                  width={80}
                  loading="eager"
                  src={
                    selectedProgram === items.programActiveImg
                      ? `/assets/icons/${items.programActiveImg}.png`
                      : `/assets/icons/${items.programInactiveImg}.png`
                  }
                />
              </div>
            ))}
          </div>
        }
      >
        <div>
          {selectedProgramData && (
            <div className="flex flex-col gap-6 p-4 mobile:pt-4 mobile:p-0 tablet:p-0">
              <div className="flex">
                {title.map((val, ind) => (
                  <div
                    key={ind}
                    className={`flex rounded-lg 
                  ${
                    subPage === ind
                      ? i18n.language === "ar"
                        ? "bg-gradient-to-l from-linearGreen"
                        : "bg-gradient-to-r from-linearGreen"
                      : " text-naturalGray400 "
                  }`}
                  >
                    <Tabs
                      title={val}
                      onSelect={() => {
                        setSubPage(ind);
                      }}
                    />
                    {ind === 0 && (
                      <div className="w-0.5 bg-grayColor mx-2"></div>
                    )}
                  </div>
                ))}
              </div>
              {firstTap && (
                <div className="flex w-full flex-col mobile:w-full tablet:w-full">
                  {/* section1 */}
                  <div className="flex mobile:flex-col">
                    <div className="flex flex-col gap-y-4 mobile:items-center tablet:items-center">
                      <p
                        className={`text-naturalGray400 max-w-[627px] mobile:h-auto h-14 ${
                          i18n.language === "ar"
                            ? "font-arMyriad text-lg leading-[18px]"
                            : "font-helveticaMedium text-sm"
                        }
                      ${"whitespace-break-spaces"}`}
                      >
                        {t(`${selectedProgramData.aboutProgram}`)}
                      </p>
                      <div
                        className={`mobile:pb-5 mobile:w-1/2 tablet:w-1/2`}
                      >
                        <Link
                          to={
                            selectedProgramData?.name === "Plus"
                              ? "http://thegarage.acceleratorapp.co/application/new?program=the-garage-plus-cohort"
                              : selectedProgramData?.name === "Incubator"
                              ? appRoutesObj.getIncubatorFormPagePath()
                              : "https://thegarage.acceleratorapp.co/application/new?program=ignition:-semiconductor-program"
                          }
                        >
                          <Button
                            className={`bg-yellow rounded-lg ${
                              i18n.language === "en"
                                ? "text-sm"
                                : "font-arMyriad text-base"
                            }`}
                            type="submit"
                            color={ColorsEnum.Primary}
                            variant={VariantsEnum.Filled}
                            size={SizesEnum.Small}
                          >
                            {selectedProgramData.name === "Ignition"
                              ? t(
                                  "containers.InternalProgramsContainer.igitionButton.applyNow"
                                )
                              : t("app.shared.button")}
                          </Button>
                        </Link>
                      </div>
                    </div>
                    {i18n.language === "en" ? (
                      <img
                        src={`/assets/images/internal-programs-stage/${selectedProgramData.programStage.imgEn}.svg`}
                        className={`w-[45%] ${selectedProgramData.name === "Plus" ? "-mt-14":"-mt-12"} mobile:-mt-0 mobile:w-[90%]`}
                        alt=""
                      /> 
                    ) : (
                      <img
                        src={`/assets/images/internal-programs-stage/${selectedProgramData.programStage.imgAr}.svg`}
                        className={`w-[45%] ${selectedProgramData.name === "Plus" ? "-mt-10":"-mt-12"} mobile:-mt-0 mobile:w-[90%]`}
                        alt=""
                      />
                    )}
                  </div>

                  <div
                    className={`mobile:-mt-0 tablet:-mt-0 ${
                      selectedProgramData.name === "Incubator"
                        ? "-mt-0"
                        : "-mt-4"
                    }`}
                  >
                    <TitleComponent
                      title="app.shared.makesUsSpecial"
                      color="text-white"
                      size={`${
                        i18n.language === "ar" ? "text-2xl" : "text-sm"
                      }`}
                    />
                    {selectedProgramData &&
                    selectedProgramData.name === "Plus" ? (
                      <div className="mobile:p-0 tablet:p-0 mobile:pt-3 tablet:pt-3">
                        <div className="h-[100%] w-full">
                          <div className="flex w-full flex-row mobile:flex-col tablet:flex-col">
                            <div className="grid w-full grid-cols-3 gap-2 tablet:grid-cols-2 mobile:grid-cols-1">
                              {selectedProgramData.whatMakeUsSpecial.map(
                                (val, index) => (
                                  <div className="w-full" key={index}>
                                    <CardComponent
                                      margin={
                                        index > 2
                                          ? "hover:-mt-20 hover:mobile:-mt-0 hover:tablet:-mt-0"
                                          : ""
                                      }
                                      icon={val.icon}
                                      title={val.label}
                                      description={val.description}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="largeLaptop:p-0 laptop:p-0 mobile:p-0 mobile:pt-3 tablet:pt-3 tablet:p-0">
                        <div className="h-[100%] w-full">
                          <div className="flex w-full flex-row mobile:flex-col tablet:flex-col">
                            <div className="grid w-full grid-cols-3 gap-4 largeLaptop:gap-2 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1">
                              {selectedProgramData.whatMakeUsSpecial.map(
                                (val, index) => (
                                  <div className="w-full" key={index}>
                                    <CardIncbatorComponent
                                      icon={val.icon}
                                      title={val.label}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* add new content here */}
              {secondTap && (
                <div className="flex w-full py-0 flex-col">
                  {/* section1 */}
                  <div
                    className={`${
                      selectedProgramData.name !== "Plus" ? "" : "hidden"
                    }`}
                  >
                    <TitleComponent
                      title="app.shared.targetSector"
                      color="text-white"
                      size={`${
                        i18n.language === "ar" ? "text-2xl" : "text-sm"
                      }`}
                    />
                    {selectedProgramData?.name === "Ignition" ? (
                      <SelectionCriteriaComponent
                        textLeft={true}
                        size="md"
                        selectionCriteriaList={selectionCriteriaIgnation}
                      />
                    ) : (
                      <div className="flex gap-4 pt-1 pb-6 mobile:flex-col">
                        {selectedProgramData.targetSectors?.map(
                          (val, index) => (
                            <div className="w-full" key={index}>
                              <div>
                                <div className="flex flex-col items-center justify-center gap-2 bg-black/20 rounded-2xl h-[50px] mobile:flex-col mobile:h-11 tablet:h-11">
                                  <p
                                    className={`text-center text-naturalGray400 ${
                                      i18n.language === "en"
                                        ? "font-helveticaMedium text-[13px]"
                                        : "font-arMyriad text-[14px]"
                                    }`}
                                  >
                                    {t(`${val.text}`)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {/* section2 */}
                  <div className="mobile:pt-6 tablet:pt-6">
                    <TitleComponent
                      color="text-white"
                      title="app.shared.selectionCriteria"
                      size={`${
                        i18n.language === "ar" ? "text-2xl" : "text-sm"
                      }`}
                    />

                    <div className="grid w-full grid-cols-3 gap-4 mobile:pt-2.5 tablet:pt-2.5 tablet:grid-cols-2 mobile:grid-cols-1">
                      {selectedProgramData.selectionCriteria.map(
                        (val, index) => (
                          <div className="w-full" key={index}>
                            <CardComponent
                              margin={
                                index > 2
                                  ? "hover:-mt-20 hover:mobile:-mt-0 hover:tablet:-mt-0"
                                  : ""
                              }
                              icon={val.icon}
                              title={val.title}
                              description={val.description}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </PopupTapsComponent>
    </>
  );
}

export default InternalProgramsContainer;
