import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import { appRoutesObj } from "./app.paths";
import Loading from "./components/common/Loading/loading";
import ToastMessage from "./components/common/toast-message/toast-message";
import AcceleratedFormPage from "./pages/user/accelerated-form-page";
import DisruptPage from "./pages/user/BookingSection/disrupt-page";
import DistruptFormPage from "./pages/user/BookingSection/distrupt-form-page";
import DisruptHiddenFormPage from "./pages/user/BookingSection/hidden-disrupt-form-page";
import BeOurPartnerFormPage from "./pages/user/BookingSection/ReservationPage/be-our-partner-page";
import BookTourFormPage from "./pages/user/BookingSection/ReservationPage/book-tour-page";
import BookVenueFormPage from "./pages/user/BookingSection/ReservationPage/book-venue-page";
import CommonQuestionsPage from "./pages/user/CommunitySection/CommonQuestionsPage/common-questions-page";
import QuestionFormPage from "./pages/user/CommunitySection/CommonQuestionsPage/question-form-page";
import MediaPage from "./pages/user/CommunitySection/Media-page";
import NewsDetailsPage from "./pages/user/CommunitySection/news-page/news-details-page";
import NewsPage from "./pages/user/CommunitySection/news-page/news-page";
import StartupPage from "./pages/user/CommunitySection/startup-page";
import TeamPage from "./pages/user/CommunitySection/team-page";
import IssuePage from "./pages/user/Issue/issue-page";
import AboutTheGaragePage from "./pages/user/LandingPage/about-thegarage-page";
import HostedPage from "./pages/user/ProgramsSection/hosted-page";
import IncubatorFormPage from "./pages/user/ProgramsSection/InternalPrograms/incubator-form-page";
import InternalProgramsPage from "./pages/user/ProgramsSection/InternalPrograms/internal-programs-page";
import PlusFormPage from "./pages/user/ProgramsSection/InternalPrograms/plus-form-page";
import PermitPage from "./pages/user/Transportation permits/permit-page";
import ScanPermitPage from "./pages/user/Transportation permits/scan-permit-page";

const NotFoundPage = React.lazy(() => import("./pages/404"));

const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={
        <div className="bg-[#262626]">
          <Loading />
        </div>
      }
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  return (
    <Suspense
      fallback={<div className="pt-10 text-primary-200">Loading...</div>}
    >
      <HelmetProvider>
        <BrowserRouter>
          <ToastMessage />
          <Routes>
            <Route
              key="homepage"
              path={appRoutesObj.getBasePath()}
              element={withSuspense(<App />)}
            />
            <Route
              key="notDefined"
              path="*"
              element={withSuspense(<NotFoundPage />)}
            />
            <Route
              key="media"
              path={appRoutesObj.getMediaPagePath()}
              element={withSuspense(<MediaPage />)}
            />
            <Route
              key="team"
              path={appRoutesObj.getTeamPagePath()}
              element={withSuspense(<TeamPage />)}
            />
            <Route
              key="news"
              path={appRoutesObj.getNewsPagePath()}
              element={withSuspense(<NewsPage />)}
            />
            <Route
              key="News Details"
              path={appRoutesObj.getNewsDetailsPagePath()}
              element={withSuspense(<NewsDetailsPage />)}
            />
            <Route
              key="hosted"
              path={appRoutesObj.getHostedPagePath()}
              element={withSuspense(<HostedPage />)}
            />
            <Route
              key="Internal Programs"
              path={appRoutesObj.getInternalProgramsPagePath()}
              element={withSuspense(<InternalProgramsPage />)}
            />
            <Route
              key="f&q"
              path={appRoutesObj.getCommonQuestionsPagePath()}
              element={withSuspense(<CommonQuestionsPage />)}
            />
            <Route
              key="question"
              path={appRoutesObj.getQuestionsFormPagePath()}
              element={withSuspense(<QuestionFormPage />)}
            />
            <Route
              key="startup"
              path={appRoutesObj.getStartUpPagePath()}
              element={withSuspense(<StartupPage />)}
            />
            <Route
              key="incubator-form"
              path={appRoutesObj.getIncubatorFormPagePath()}
              element={withSuspense(<IncubatorFormPage />)}
            />
            <Route
              key="disrupt"
              path={appRoutesObj.getDisruptPagePath()}
              element={withSuspense(<DisruptPage />)}
            />
            <Route
              key="issue"
              path={appRoutesObj.getIssuePagePath()}
              element={withSuspense(<IssuePage />)}
            />
            <Route
              key="distrupt-form"
              path={appRoutesObj.getDistruptFormPath()}
              element={withSuspense(<DistruptFormPage />)}
            />
            <Route
              key="AboutTheGarage"
              path={appRoutesObj.getAboutThegaragePagePath()}
              element={withSuspense(<AboutTheGaragePage />)}
            />
            <Route
              key="GaragePlusForm"
              path={appRoutesObj.getPlusFormPagePath()}
              element={withSuspense(<PlusFormPage />)}
            />
            <Route
              key="book-tour"
              path={appRoutesObj.getBookTourFormPagePath()}
              element={withSuspense(<BookTourFormPage />)}
            />
            <Route
              key="be-our-partner"
              path={appRoutesObj.getBeOurPartnerFormPagePath()}
              element={withSuspense(<BeOurPartnerFormPage />)}
            />
            <Route
              key="book-venue"
              path={appRoutesObj.getBookVenueFormPagePath()}
              element={withSuspense(<BookVenueFormPage />)}
            />
            <Route
              key="disrupt"
              path={appRoutesObj.getDisruptHiddenPagePath()}
              element={withSuspense(<DisruptHiddenFormPage />)}
            />
            <Route
              key="transportation-permit"
              path={appRoutesObj.getPermitHiddenPagePath()}
              element={withSuspense(<PermitPage />)}
            />
               <Route
              key="scan-permit"
              path={appRoutesObj.getScanPermitPagePath()}
              element={withSuspense(<ScanPermitPage />)}
            />

<Route
              key="accelerated"
              path={appRoutesObj.getAcceleratedFormPagePath()}
              element={withSuspense(<AcceleratedFormPage />)}
            />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </Suspense>
  );
}
